
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect } from "react";
import Script from "next/script";
import { SessionProvider } from "next-auth/react";
import { ToastContainer } from "react-toastify";
import type { AppProps } from "next/app";
import "../styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/router";
import { facebookPixel } from "lib/fpixel";
import { trpc } from "lib/trpc";
import { mixpanel } from "lib/mixpanel";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { GoogleAnalytics, usePageViews } from "nextjs-google-analytics";
import mobileApp, { useMobileAppHelper } from "lib/mobileApp";
import dynamic from "next/dynamic";
const AppWithPolarisWrapper = dynamic(() => import("ui_components/AppWithPolarisWrapper"));
if (typeof window !== "undefined" &&
    window.location.href.includes("localhost") == false) {
    // checks that we are client-side
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
        loaded: (posthog) => {
            if (process.env.NODE_ENV === "development")
                posthog.debug(); // debug mode in development
        },
        autocapture: false,
        capture_pageview: false, // we will track page views manually
    });
}
// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
const App = ({ Component, pageProps }: AppProps) => {
    usePageViews();
    const router = useRouter();
    const { isMobileApp } = useMobileAppHelper();
    // If we're in the app, we will wrap the content with Polaris
    // We leave Polaris out of marketing pages to avoid loading unnecessary code
    const isAppPage = router.pathname.startsWith("/app");
    const isEventsPublicPage = router.pathname.startsWith("/e/");
    // We don't want to render these scripts or track page views for the booking sites or events public pages
    const dontRenderFacebookPixelOrToltOrTrackPageViews = pageProps?.metadata?.isBookingSite || isEventsPublicPage;
    useEffect(() => {
        if (!dontRenderFacebookPixelOrToltOrTrackPageViews) {
            // This pageview only triggers the first time (it's important for Pixel to have real information)
            facebookPixel.pageview();
            mixpanel.track("Page view", {
                url: window?.location.pathname,
            });
        }
        const handleRouteChange = (url: string) => {
            if (dontRenderFacebookPixelOrToltOrTrackPageViews)
                return;
            facebookPixel.pageview();
            mixpanel.track("Page view", {
                url,
            });
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
    useEffect(() => {
        function handleClick(e: MouseEvent) {
            if (!isMobileApp)
                return;
            const target = e.target as HTMLElement;
            if (target.tagName === "A") {
                const targetAttribute = target.getAttribute("target");
                if (targetAttribute === "_blank") {
                    e.preventDefault();
                    mobileApp.openLink(target.getAttribute("href")!);
                }
            }
        }
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [isMobileApp]);
    const content = (<PostHogProvider client={posthog}>
      <SessionProvider session={pageProps.session} refetchInterval={0}>
        <ToastContainer></ToastContainer>
        <Component {...pageProps}/>
      </SessionProvider>
    </PostHogProvider>);
    return (<>
      <GoogleAnalytics gaMeasurementId="G-42SB0571B3" trackPageViews/>
      {/* Global Site Code Pixel - Facebook Pixel */}
      {!dontRenderFacebookPixelOrToltOrTrackPageViews && (<>
          <Script id="fb-pixel" strategy="afterInteractive" dangerouslySetInnerHTML={{
                __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${facebookPixel.FB_PIXEL_ID});
            fbq('init', '849956046917298');
          `,
            }}/>
          <Script id="tolt" async src="https://cdn.tolt.io/tolt.js" data-tolt="92c79655-6307-4acb-980c-b1b2f4771b9a"/>
        </>)}
      {typeof window != "undefined" && !(window as any).mixpanel && (<Script strategy="afterInteractive" dangerouslySetInnerHTML={{
                __html: `
              (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
            mixpanel.init('382407ea2baf5b2c3b225fb16bfb8d55', {debug: ${process.env.NODE_ENV === "development"}});
            // mixpanel.track('Sign up');`,
            }}/>)}

      {isAppPage ? (<AppWithPolarisWrapper>{content}</AppWithPolarisWrapper>) : (content)}
    </>);
};
const __Next_Translate__Page__1920685be12__ = trpc.withTRPC(App);

    export default __appWithI18n(__Next_Translate__Page__1920685be12__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  